import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAddNewFirmaMutation,
  useUpdateFirmaMutation,
} from "../firmeApiSlice";
import {
  Input,
  Select,
  Button,
  Divider,
  message,
  Tooltip,
  Modal,
  Space,
  Image,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  AppstoreAddOutlined,
  RightSquareOutlined,
} from "@ant-design/icons";
import iconPhoto from "../../../resourses/cameraIcon.png";
import intrariPhoto from "../../../resourses/intrari.png";

const initalConta = {
  intrari: true,
  iesiri: true,
  incasari: true,
  plati: true,
};

function FirmaNouForm(props) {
  const { userId, idFirma } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [addNewFirma, { isLoading, isSuccess }] = useAddNewFirmaMutation();

  const [updateFirma] = useUpdateFirmaMutation();

  const navigate = useNavigate();

  const [numeFirma, setNumeFirma] = useState(idFirma ? idFirma.numeFirma : "");
  const [cif, setCif] = useState(idFirma ? idFirma.cif : "");
  const [conta, setConta] = useState(idFirma ? idFirma.conta : initalConta);

  ////////////////////////
  const [editIntrare, setEditIntrare] = useState(null);
  const [intrare, setIntrare] = useState("");
  const [contIntrari, setContIntrari] = useState(
    idFirma ? idFirma.contIntrari : []
  );
  ////////////////////////

  const [editExceptie, setEditExceptie] = useState(null);
  const [exceptie, setExceptie] = useState("");

  const [exceptieIesire, setExceptieIesire] = useState(null);

  const [editIesire, setEditIesire] = useState(null);
  const [iesire, setIesire] = useState("");
  const [contIesiri, setContIesiri] = useState(
    idFirma ? idFirma.contIesiri : []
  );

  ////////////////////////
  const [editIncasare, setEditIncasare] = useState(null);
  const [incasare, setIncasare] = useState("");
  const [contIncasari, setContIncasari] = useState(
    idFirma ? idFirma.contIncasari : []
  );
  ////////////////////////

  useEffect(() => {
    if (isSuccess) {
      navigate("/firme");
    }
  }, [isSuccess, navigate]);

  const canSave = [userId, numeFirma, cif].every(Boolean) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (idFirma) {
      if (canSave) {
        await updateFirma({
          id: idFirma,
          user: userId,
          numeFirma: numeFirma,
          cif: cif,
          conta: conta,
          contIntrari: contIntrari,
          contIesiri: contIesiri,
          contIncasari: contIncasari,
        });

        message.success("Datele au fost trimise!");
        navigate("/firme");
      } else {
        message.error("Introduceti datele companiei!");
      }
    } else {
      if (canSave) {
        await addNewFirma({
          user: userId,
          numeFirma: numeFirma,
          cif: cif,
          conta: conta,
          contIntrari: contIntrari,
          contIesiri: contIesiri,
          contIncasari: contIncasari,
        });

        message.success("Datele au fost trimise!");
      } else {
        message.error("Introduceti datele companiei!");
      }
    }
  };

  const onNumeFirmaChanged = (e) => setNumeFirma(e.target.value);
  const onCifChanged = (e) => setCif(e.target.value.toUpperCase());
  const onContaChanged = (e) => {
    const { id, checked } = e.target;
    setConta({
      ...conta,
      [id]: checked,
    });
  };

  /////////////////////////////////////////////////////////////////

  const onIntrareChanged = (e) => {
    const { name, value } = e.target;
    setIntrare({
      ...intrare,
      [name]: value,
    });
  };

  const onContIntrariChanged = () => {
    if (editIntrare === null) {
      if (intrare.gestiune !== "" && intrare.cont !== "") {
        setContIntrari([
          ...contIntrari,
          {
            id2: Math.floor(Math.random() * 10000000000001),
            gestiune: intrare.gestiune,
            cont: Number(intrare.cont),
          },
        ]);
        setIntrare("");
      }
    } else {
      const editIntr = contIntrari.map((obj) => {
        if (obj.id2 === editIntrare.id2) {
          return {
            ...obj,
            gestiune: intrare.gestiune,
            cont: Number(intrare.cont),
          };
        }
        return obj;
      });

      setContIntrari(editIntr);
      setEditIntrare(null);
      setIntrare("");
    }
  };

  /////////////////////////////////////////////////////////////////////////////////

  const onIesireChanged = (e) => {
    const { name, value } = e.target;
    setIesire({
      ...iesire,
      [name]: value,
    });
  };

  const onContIesiriChanged = () => {
    if (editIesire === null) {
      if (
        iesire.denumire !== "" &&
        iesire.filtrareUM !== "" &&
        iesire.cont !== ""
      ) {
        setContIesiri([
          ...contIesiri,
          {
            id2: Math.floor(Math.random() * 10000000000001),
            denumire: iesire.denumire,
            filtrareUM: iesire.filtrareUM,
            um: iesire.um,
            cont: Number(iesire.cont),
            exceptii: [],
          },
        ]);
        setIesire("");
      }
    } else {
      const editIesi = contIesiri.map((obj) => {
        if (obj.id2 === editIesire.id2) {
          return {
            ...obj,
            denumire: iesire.denumire,
            filtrareUM: iesire.filtrareUM,
            um: iesire.um,
            cont: Number(iesire.cont),
            exceptii: iesire.exceptii,
          };
        }
        return obj;
      });

      setContIesiri(editIesi);
      setEditIesire(null);
      setIesire("");
    }
  };
  ///////////////////////////

  const onExceptieChanged = (e) => {
    if (e !== undefined) {
      const { name, value } = e.target;
      setExceptie({
        ...exceptie,
        [name]: value,
      });
    }
  };

  const onContExceptiiChanged = () => {
    if (
      exceptie.denumire !== undefined &&
      exceptie.exceptie !== undefined &&
      exceptie.cont !== undefined &&
      exceptie.um !== undefined
    ) {
      if (editExceptie === null) {
        const editExcep = contIesiri.map((obj) => {
          if (obj.id2 === exceptieIesire.id2) {
            return {
              ...obj,
              exceptii: [
                ...obj.exceptii,
                {
                  id2: Math.floor(Math.random() * 10000000000001),
                  denumire: exceptie.denumire,
                  exceptie: exceptie.exceptie,
                  um: exceptie.um,
                  cont: Number(exceptie.cont),
                },
              ],
            };
          }
          return obj;
        });
        setContIesiri(editExcep);
        setExceptie("");
        setIsModalOpen(false);
        setExceptieIesire(null);
        setEditExceptie(null);
      } else {
        const editExcep = contIesiri.map((obj) => {
          if (obj.id2 === exceptieIesire.id2) {
            const gigi = obj.exceptii.map((except) => {
              if (except.id2 === editExceptie.id2) {
                return {
                  ...except,
                  denumire: exceptie.denumire,
                  exceptie: exceptie.exceptie,
                  um: exceptie.um,
                  cont: Number(exceptie.cont),
                };
              }
              return except;
            });
            return {
              ...obj,
              exceptii: gigi,
            };
          }
          return obj;
        });
        setContIesiri(editExcep);
        setExceptie("");
        setIsModalOpen(false);
        setExceptieIesire(null);
        setEditExceptie(null);
      }
    } else {
      if (exceptie.denumire === undefined)
        message.error("Introduceti o denumire!");
      if (exceptie.exceptie === undefined)
        message.error("Introduceti o exceptie!");
      if (exceptie.cont === undefined)
        message.error("Introduceti un nr. de cont!");
      if (exceptie.um === undefined)
        message.error("Introduceti o unitate de masura!");
    }
  };

  ///////////////////////////

  const onIncasareChanged = (e) => {
    const { name, value } = e.target;
    setIncasare({
      ...incasare,
      [name]: value,
    });
  };

  const onIncasareSelectChanged = (e) => {
    setIncasare({
      ...incasare,
      tipTranzactie: e,
    });
  };

  const onContIncasariChanged = () => {
    if (editIncasare === null) {
      if (incasare.tipTranzactie !== "" && incasare.cont !== "") {
        setContIncasari([
          ...contIncasari,
          {
            id2: Math.floor(Math.random() * 10000000000001),
            tipTranzactie: incasare.tipTranzactie,
            cont: Number(incasare.cont),
          },
        ]);
        setIncasare("");
      }
    } else {
      const editInca = contIncasari.map((obj) => {
        if (obj.id2 === editIncasare.id2) {
          return {
            ...obj,
            tipTranzactie: incasare.tipTranzactie,
            cont: Number(incasare.cont),
          };
        }
        return obj;
      });

      setContIncasari(editInca);
      setEditIncasare(null);
      setIncasare("");
    }
  };

  ///////////////////////////

  const onDeleteCont = (item, array, setCont) => {
    const newList = array.filter((e) => e.id2 !== item.id2);
    setCont(newList);
  };

  const onDeleteContExceptii = (item, array, setCont, intr) => {
    const newArray = array.map((elem) => {
      if (elem.id2 === intr.id2) {
        const newList = intr.exceptii.filter((e) => e.id2 !== item.id2);
        return {
          ...elem,
          exceptii: newList,
        };
      }
      return elem;
    });
    setCont(newArray);
  };

  const content = (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            minWidth: "40rem",
            maxWidth: "40rem",
          }}
        >
          Completati datele firmei:
          <div style={{ display: "flex", marginTop: "10px" }}>
            <Input
              type="text"
              placeholder="Numele firmei"
              autoComplete="off"
              value={numeFirma}
              name="numeFirma"
              onChange={onNumeFirmaChanged}
            />
            <Input
              type="text"
              placeholder="Cif-ul firmei"
              autoComplete="off"
              value={cif}
              name="cif"
              onChange={onCifChanged}
            />
          </div>
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <input
                style={{ marginRight: "7px" }}
                type="checkbox"
                autoComplete="off"
                checked={conta.intrari}
                name="intrari"
                id="intrari"
                onChange={onContaChanged}
              />
              Intrari
            </div>
            <div>
              <input
                style={{ marginRight: "7px" }}
                type="checkbox"
                autoComplete="off"
                checked={conta.iesiri}
                name="iesiri"
                id="iesiri"
                onChange={onContaChanged}
              />
              Iesiri
            </div>
            <div>
              <input
                style={{ marginRight: "7px" }}
                type="checkbox"
                autoComplete="off"
                checked={conta.incasari}
                name="incasari"
                id="incasari"
                onChange={onContaChanged}
              />
              Incasari
            </div>
            <div>
              <input
                style={{ marginRight: "7px" }}
                type="checkbox"
                autoComplete="off"
                checked={conta.plati}
                name="plati"
                id="plati"
                onChange={onContaChanged}
              />
              Plati
            </div>
          </div>
          {conta.intrari && (
            <>
              <Divider />
              <div>
                Gestiuni de intrare:
                <div style={{ display: "flex" }}>
                  <Space.Compact
                    style={{
                      width: "100%",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="Gestiune"
                      autoComplete="off"
                      value={intrare.gestiune}
                      name="gestiune" //IMPORTANT
                      onChange={onIntrareChanged}
                      suffix={
                        <Tooltip title="Introduceti denumirea gestiunii de intrare exact ca in Smartbill.">
                          <InfoCircleOutlined
                            style={{
                              color: "rgba(0,0,0,.45)",
                            }}
                          />
                        </Tooltip>
                      }
                    />
                    <Button>
                      <Image
                        width={20}
                        preview={{
                          src: intrariPhoto,
                        }}
                        src={iconPhoto}
                      />
                    </Button>
                  </Space.Compact>
                  <Input
                    type="number"
                    placeholder="Cont"
                    autoComplete="off"
                    value={intrare.cont}
                    name="cont" //IMPORTANT
                    onChange={onIntrareChanged}
                    suffix={
                      <Tooltip title="Introduceti contul din SAGA pentru aceasta gestiune de intrare.">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  />
                  <Button
                    type="primary"
                    size="normal"
                    onClick={onContIntrariChanged}
                  >
                    {`${editIntrare !== null ? "Salveaza" : "Adauga"}`}
                  </Button>
                </div>
                {contIntrari.map((intr, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "5px",
                      marginRight: "10px",
                      borderBottom: "solid",
                      borderWidth: "1px",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                      }}
                    >
                      Gestiune:
                      <div
                        style={{
                          marginLeft: "5px",
                          color: "#1b57fa",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        {intr.gestiune}
                      </div>
                    </div>
                    <div style={{ width: "40%", display: "flex" }}>
                      Cont:
                      <div style={{ marginLeft: "5px", color: "#1b57fa" }}>
                        {intr.cont}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "10%",
                      }}
                    >
                      <Tooltip title="Modifica datele">
                        <EditOutlined
                          onClick={() => {
                            setIntrare(intr);
                            setEditIntrare(intr);
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Sterge datele">
                        <DeleteOutlined
                          onClick={() =>
                            onDeleteCont(intr, contIntrari, setContIntrari)
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {conta.iesiri && (
            <>
              <Divider />
              <div>
                Gestiuni de iesire:
                <div style={{ display: "flex" }}>
                  <Input
                    type="text"
                    placeholder="Denumire"
                    autoComplete="off"
                    value={iesire.denumire}
                    name="denumire" //IMPORTANT
                    onChange={onIesireChanged}
                    suffix={
                      <Tooltip title="Introduceti o denumire pentru aceasta gestiune de iesire. Ex. Manopera">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Unitati de masura"
                    autoComplete="off"
                    value={iesire.filtrareUM}
                    name="filtrareUM" //IMPORTANT
                    onChange={onIesireChanged}
                    suffix={
                      <Tooltip title="Introduceti toate unitatile de masura care sa fie adaugate la contul setat. Ex. buc set litru">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  />
                  <Input
                    type="text"
                    placeholder="U.M. in SAGA"
                    autoComplete="off"
                    value={iesire.um}
                    name="um" //IMPORTANT
                    onChange={onIesireChanged}
                    suffix={
                      <Tooltip title="Introduceti unitatea de masura care sa apara in SAGA dupa import. Ex. BUC">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  />
                  <Input
                    type="number"
                    placeholder="Cont"
                    autoComplete="off"
                    value={iesire.cont}
                    name="cont" //IMPORTANT
                    onChange={onIesireChanged}
                    suffix={
                      <Tooltip title="Introduceti contul din SAGA pentru aceasta gestiune de iesire.">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  />
                  <Button
                    type="primary"
                    size="normal"
                    onClick={onContIesiriChanged}
                  >
                    {`${editIesire !== null ? "Salveaza" : "Adauga"}`}
                  </Button>
                </div>
                {contIesiri.map((intr, i) => (
                  <div
                    key={i}
                    style={{ borderBottom: "solid", borderWidth: "1px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50%",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          Denumire:
                          <div
                            style={{
                              marginLeft: "5px",
                              color: "#1b57fa",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "inline-block",
                            }}
                          >
                            {intr.denumire}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          U.M. filtrare date:
                          <div
                            style={{
                              marginLeft: "5px",
                              color: "#1b57fa",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "inline-block",
                            }}
                          >
                            {intr.filtrareUM}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          U.M. SAGA:
                          <div style={{ marginLeft: "5px", color: "#1b57fa" }}>
                            {intr.um}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          Cont:
                          <div style={{ marginLeft: "5px", color: "#1b57fa" }}>
                            {intr.cont}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "10%",
                        }}
                      >
                        <Tooltip title="Modifica datele">
                          <EditOutlined
                            onClick={() => {
                              setIesire(intr);
                              setEditIesire(intr);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Adauga exceptie">
                          <AppstoreAddOutlined
                            onClick={() => {
                              setIsModalOpen(true);
                              setExceptieIesire(intr);

                              setExceptie("");
                            }}
                          />
                        </Tooltip>

                        <Tooltip title="Sterge datele">
                          <DeleteOutlined
                            onClick={() =>
                              onDeleteCont(intr, contIesiri, setContIesiri)
                            }
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <Modal
                      title={`${
                        editExceptie !== null
                          ? "Modifica exceptia"
                          : "Adauga o exceptie"
                      }`}
                      open={isModalOpen}
                      centered
                      footer={
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => onContExceptiiChanged()}
                        >
                          {`${editExceptie !== null ? "Salveaza" : "Adauga"}`}
                        </Button>
                      }
                      onCancel={() => {
                        setEditExceptie(null);
                        setIsModalOpen(false);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          margin: "5px",
                          width: "90%",
                          float: "right",
                        }}
                      >
                        <Input
                          type="text"
                          size="small"
                          placeholder="Denumire"
                          autoComplete="off"
                          value={exceptie.denumire}
                          name="denumire" //IMPORTANT
                          onChange={onExceptieChanged}
                          suffix={
                            <Tooltip title="Introduceti o denumire pentru aceasta exceptiei.">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                }}
                              />
                            </Tooltip>
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") onContExceptiiChanged();
                          }}
                        />
                        <Input
                          type="text"
                          size="small"
                          placeholder="U.M. in SAGA"
                          autoComplete="off"
                          value={exceptie.um}
                          name="um" //IMPORTANT
                          onChange={onExceptieChanged}
                          suffix={
                            <Tooltip title="Introduceti unitatea de masura care sa apara in SAGA dupa import. Ex. BUC">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                }}
                              />
                            </Tooltip>
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") onContExceptiiChanged();
                          }}
                        />
                        <Input
                          type="number"
                          size="small"
                          placeholder="Cont"
                          autoComplete="off"
                          value={exceptie.cont}
                          name="cont" //IMPORTANT
                          onChange={onExceptieChanged}
                          suffix={
                            <Tooltip title="Introduceti contul din SAGA pentru aceasta iesire.">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                }}
                              />
                            </Tooltip>
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") onContExceptiiChanged();
                          }}
                        />
                        <Input
                          type="text"
                          size="small"
                          placeholder="Exceptie"
                          autoComplete="off"
                          value={exceptie.exceptie}
                          name="exceptie" //IMPORTANT
                          onChange={onExceptieChanged}
                          suffix={
                            <Tooltip title="Introduceti denumirea exacta care apare pe factura la rubrica 'Denumirea produselor sau a serviciilor' dupa care sa se diferentieze contul.">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                }}
                              />
                            </Tooltip>
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") onContExceptiiChanged();
                          }}
                        />
                      </div>
                    </Modal>
                    <RightSquareOutlined />
                    &nbsp;Exceptii:
                    {intr.exceptii?.length > 0 ? (
                      intr.exceptii.map((excep, i) => (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            width: "60%",
                            marginLeft: "20px",
                            justifyContent: "space-around",
                            color: "#1b57fa",
                          }}
                        >
                          <div style={{ width: "30%" }}>{excep.denumire}</div>
                          <div style={{ width: "15%" }}>{excep.um}</div>
                          <div style={{ width: "15%" }}>{excep.cont}</div>
                          <div style={{ width: "30%" }}>{excep.exceptie}</div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              width: "10%",
                              color: "black",
                            }}
                          >
                            <Tooltip title="Modifica datele">
                              <EditOutlined
                                onClick={() => {
                                  setExceptie(excep);
                                  setEditExceptie(excep);
                                  setIsModalOpen(true);
                                  setExceptieIesire(intr);
                                }}
                              />
                            </Tooltip>

                            <Tooltip title="Sterge datele">
                              <DeleteOutlined
                                onClick={() =>
                                  // onDeleteCont(excep, contIesiri, setContIesiri)
                                  onDeleteContExceptii(
                                    excep,
                                    contIesiri,
                                    setContIesiri,
                                    intr
                                  )
                                }
                              />
                            </Tooltip>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div style={{ marginLeft: "20px" }}>
                        Nu exista exceptii!
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          {conta.incasari && (
            <>
              <Divider />
              <div>
                Conturi incasari:
                <div style={{ display: "flex" }}>
                  <Select
                    style={{ width: "100%" }}
                    options={[
                      {
                        value: "Numerar",
                        label: "Numerar",
                      },
                      {
                        value: "Card",
                        label: "Card",
                      },
                      {
                        value: "Chitanta",
                        label: "Chitanta",
                      },
                      {
                        value: "Ordin plata",
                        label: "Ordin plata",
                      },
                      {
                        value: "Card online",
                        label: "Card online",
                      },
                      {
                        value: "Mandat postal",
                        label: "Mandat postal",
                      },
                      {
                        value: "CEC",
                        label: "CEC",
                      },
                      {
                        value: "Bilet ordin",
                        label: "Bilet ordin",
                      },
                      {
                        value: "Alta incasare",
                        label: "Alta incasare",
                      },
                      {
                        value: "Extras de cont",
                        label: "Extras de cont",
                      },
                      {
                        value: "Ramburs",
                        label: "Ramburs",
                      },
                    ]}
                    placeholder="Tip incasare"
                    value={incasare.tipTranzactie}
                    name="tipTranzactie" //IMPORTANT
                    onChange={onIncasareSelectChanged}
                    suffix={
                      <Tooltip title="Introduceti denumirea tipului de incasare dorit. Ex. Numerar">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  />
                  {/* <Input
                    type="text"
                    placeholder="Tip incasare"
                    autoComplete="off"
                    value={incasare.tipTranzactie}
                    name="tipTranzactie" //IMPORTANT
                    onChange={onIncasareChanged}
                    suffix={
                      <Tooltip title="Introduceti denumirea tipului de incasare dorit. Ex. Numerar">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  /> */}
                  <Input
                    type="number"
                    placeholder="Cont"
                    autoComplete="off"
                    value={incasare.cont}
                    name="cont" //IMPORTANT
                    onChange={onIncasareChanged}
                    suffix={
                      <Tooltip title="Introduceti contul din SAGA pentru acest tip de incasare.">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      </Tooltip>
                    }
                  />
                  <Button
                    type="primary"
                    size="normal"
                    onClick={onContIncasariChanged}
                  >
                    {`${editIncasare !== null ? "Salveaza" : "Adauga"}`}
                  </Button>
                </div>
                {contIncasari?.map((intr, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "5px",
                      marginRight: "10px",
                      borderBottom: "solid",
                      borderWidth: "1px",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                      }}
                    >
                      Tip incasare:
                      <div
                        style={{
                          marginLeft: "5px",
                          color: "#1b57fa",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        {intr.tipTranzactie}
                      </div>
                    </div>
                    <div style={{ width: "40%", display: "flex" }}>
                      Cont:
                      <div style={{ marginLeft: "5px", color: "#1b57fa" }}>
                        {intr.cont}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "10%",
                      }}
                    >
                      <EditOutlined
                        onClick={() => {
                          setIncasare(intr);
                          setEditIncasare(intr);
                        }}
                      />
                      <DeleteOutlined
                        onClick={() =>
                          onDeleteCont(intr, contIncasari, setContIncasari)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button type="primary" size="normal" onClick={handleSubmit}>
              Salveaza Datele
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  return content;
}

export default FirmaNouForm;
