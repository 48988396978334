import React, { useRef, useEffect, useState } from "react";
import { Spin, Modal, Divider, Card, Button } from "antd";
import { getPlati } from "../source/api";
import { CloudDownloadOutlined, DownloadOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";

function Plati({ CIF, NumeCompanie, fisiere, rangeDate, token, idUser }) {
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [mesajVin, setMesajVin] = useState("");
  const [color, setColor] = useState("black");
  const [printDevizModalVisibility, setPrintDevizModalVisibilty] =
    useState(false);

  const [isPrinting, setIsPrinting] = useState(false);
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
    documentTitle: `Interval raport ${rangeDate} - ${NumeCompanie}`,
  });

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  let txt = <>Va rugam asteptati!</>;

  useEffect(() => {
    setItems([]);
    setMesajVin("");
  }, [fisiere]);

  async function addPlati() {
    setLoading(true);

    const getExtractPlati = await getPlati({ CIF, rangeDate, token, idUser });

    if (getExtractPlati.data === "eroare") {
      setItems([]);
      setMesajVin("A aparut o eroare! Incercati din nou!");
      setColor("red");
    } else {
      setItems(getExtractPlati.data);
      setMesajVin(getExtractPlati.mesaj);
      setColor(getExtractPlati.color);
    }

    setLoading(false);
  }

  function vizualizarePlati() {
    setPrintDevizModalVisibilty(true);
  }

  return (
    <>
      <Spin tip={txt} size="large" spinning={isLoading}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Card
            loading={isLoading}
            actions={[
              items.length === 0 ? (
                <Button
                  icon={<CloudDownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px" }}
                  type="link"
                  key="extrage"
                  onClick={() => addPlati()}
                >
                  Extrage datele
                </Button>
              ) : (
                <Button
                  icon={<DownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px" }}
                  type="link"
                  key="descarca"
                  onClick={() => vizualizarePlati()}
                >
                  Vizualizeaza incasarile
                </Button>
              ),
            ]}
            style={{
              minWidth: 300,
            }}
          >
            <Card.Meta
              title={"Plati OP"}
              description={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      color: "black",
                      alignItems: "center",
                    }}
                  >
                    {items.length === 0 && <>Extragerea platilor</>}
                    <div style={{ color: `${color}`, fontWeight: "bold" }}>
                      {mesajVin}
                    </div>
                    {items.length > 0 && (
                      <div>Numarul de plati OP este: {items.length}</div>
                    )}
                  </div>
                </>
              }
            />
          </Card>
        </div>
      </Spin>

      {printDevizModalVisibility && (
        <Modal
          onCancel={() => {
            setPrintDevizModalVisibilty(false);
          }}
          open={printDevizModalVisibility}
          title="Vizualizare plati OP"
          footer={false}
          width={840}
        >
          <div className="deviz-button d-flex justify-content-center">
            <Button type="primary" onClick={handlePrint}>
              Printeaza raportul
            </Button>
          </div>
          <div
            ref={componentRef}
            style={{ color: "black", marginLeft: "15px" }}
          >
            {items?.map((row, index) => (
              <div key={index}>
                <Divider />
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div style={{ marginRight: "10px" }}>{index + 1 + "."}</div>
                  <div>
                    {row.dataPlatii} - {row.furnizor} - Suma:{" "}
                    {row.valoareaTotala} RON
                  </div>
                </div>
                <div>{row.facturi}</div>
              </div>
            ))}
            <Divider />
          </div>
        </Modal>
      )}
    </>
  );
}

export default Plati;
