import React from "react";
import UsersList from "../features/user/UsersList";
import FirmeList from "../features/firme/FirmeList";
import { Divider } from "antd";

function Setari() {
  return (
    <>
      <Divider />

      <UsersList />

      <FirmeList />

      <Divider />
    </>
  );
}

export default Setari;
