import { store } from "../../app/store";
// import { mechanicsApiSlice } from "../mechanics/mechanicsApiSlice";
import { firmeApiSlice } from "../firme/firmeApiSlice";
import { usersApiSlice } from "../user/usersApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Prefetch = () => {
  const { id } = useAuth();

  useEffect(() => {
    store.dispatch(
      usersApiSlice.util.prefetch("getUser", id, {
        force: true,
      })
    );
  }, [id]);

  useEffect(() => {
    store.dispatch(
      firmeApiSlice.util.prefetch("getFirme", id, {
        force: true,
      })
    );
  }, [id]);

  return <Outlet />;
};
export default Prefetch;
