import React from "react";
import useAuth from "../../hooks/useAuth";
import FirstLogin from "../../features/auth/FirstLogin";
import Setari from "../../pages/Setari";

function Homepage() {
  const { email, cif, id, active } = useAuth();

  return (
    <>
      {!cif && <FirstLogin email={email} id={id} active={active} />}

      <Setari />
    </>
  );
}

export default Homepage;
