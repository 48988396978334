import React, { useEffect, useState, useRef } from "react";
import X2JS from "x2js";
import { Spin, Card, Button, Modal } from "antd";
import { getIesiri } from "../source/api";
import { apiUrl } from "../../../app/url";
import { CloudDownloadOutlined, DownloadOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";

function assignKey(obj, sort, val) {
  const key = sort[0].denumire;

  typeof obj[key] === "undefined"
    ? (obj[key] = {
        cont: sort[0].cont,
        um: sort[0].um,
        tva: Number(val.TVA),
        valuare: Number(val.Valoare),
        procTVA: val.ProcTVA,
      })
    : (obj[key] = {
        cont: sort[0].cont,
        um: sort[0].um,
        tva: Number(val.TVA) + obj[key].tva,
        valuare: Number(val.Valoare) + obj[key].valuare,
        procTVA: val.ProcTVA,
      });
}

function Iesiri({ CIF, conturiIesiri, fisiere, rangeDate, token, idUser }) {
  const [items, setItems] = useState([]);
  const [count, setCount] = useState([]);
  const [xmlFile, setXmlFile] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const [mesajVin, setMesajVin] = useState("");
  const [color, setColor] = useState("black");
  const [fisierClienti, setFisierClienti] = useState("");
  const [eroareUM, setEroareUM] = useState([]);

  const ref = useRef();

  const navigate = useNavigate();

  const { idFirma } = useParams();

  useEffect(() => {
    setItems([]);
    setMesajVin("");
  }, [fisiere]);

  function liniiFactura(linie2) {
    let saga = {};
    let array = [];
    let linie = linie2.Linie;
    if (linie.length === undefined) {
      linie = Object.keys(linie2).map((key) => linie2[key]);
    }

    for (const [key, val] of Object.entries(linie)) {
      if (Number(key) || key === "0") {
        const sortare1 = conturiIesiri?.filter((e) =>
          e.filtrareUM.includes(val.UM)
        );
        // console.log(sortare1);
        if (sortare1.length !== 0) {
          const sortare2 = sortare1?.map((e) => {
            const exceptii = e.exceptii?.filter((e) =>
              val.Descriere.includes(e.exceptie)
            );
            if (!exceptii?.length) {
              return e;
            } else {
              return exceptii[0];
            }
          });
          assignKey(saga, sortare2, val);
        } else {
          setEroareUM((prevItems) => [...prevItems, val.UM]);
        }
      }
    }

    let i = 0;
    for (const [key, value] of Object.entries(saga)) {
      // console.log(`${key}: ${value.cont}`);
      i++;
      array.push({
        LinieNrCrt: `${i}`,
        Gestiune: "",
        Activitate: "",
        Descriere: "",
        CodArticolFurnizor: "",
        CodArticolClient: "",
        CodBare: "",
        InformatiiSuplimentare: "",
        UM: value.um,
        Cantitate: "1",
        Pret: "",
        Valoare: value.valuare.toFixed(2),
        CotaTVA: value.procTVA,
        ProcTVA: value.procTVA,
        TVA: value.tva.toFixed(2),
        Cont: value.cont,
      });
    }

    delete linie2.Linie;
    linie2.Linie = array;
  }

  function xtojsConvert(item) {
    let x2js = new X2JS();
    let document = x2js.xml2js(item);

    const obj = document?.Facturi.Factura;

    obj ? setCount(obj) : setCount([]);
    obj?.forEach((x) => {
      x.Antet.FacturaNumar = x.Antet.FacturaNumar.toUpperCase();

      let linii = x.Detalii.Continut;
      liniiFactura(linii);
    });

    let xml = x2js.js2xml(document);

    setXmlFile(xml);
  }

  // console.log([...new Set(eroareUM)]);

  useEffect(() => {
    xtojsConvert(items);

    // eslint-disable-next-line
  }, [items]);

  function handleSubmit() {
    const data_str =
      "data:text/xml;charset=utf-8," + encodeURIComponent(xmlFile);
    let anchor = document.createElement("a");
    anchor.setAttribute("href", data_str);
    anchor.setAttribute(
      "download",
      `F_${CIF}_IESIRI_${
        Math.floor(Math.random() * 10001) + 9999
      }_${getData()}.xml`
    );
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();

    ref.current && (ref.current.value = "");
  }

  const downloadClientsFile = () => {
    fetch(`${apiUrl}/download/${idUser}/${fisierClienti[0]}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fisierClienti[0] || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  async function extrageDatele() {
    setLoading(true);

    const getExtractIesiri = await getIesiri({ CIF, rangeDate, token, idUser });

    if (getExtractIesiri.data === "eroare" || !getExtractIesiri.data.length) {
      setItems(getExtractIesiri.data);
      setMesajVin(getExtractIesiri.mesaj);
      setColor(getExtractIesiri.color);
    } else {
      setFisierClienti(getExtractIesiri.data.filter((x) => x.includes("cli_")));
      const fisierIesiri = getExtractIesiri.data.filter((x) =>
        x.includes("_multiple_")
      );
      fetch(`${apiUrl}/download/${idUser}/${fisierIesiri[0]}`)
        .then((response) => response.text())
        .then((text) => setItems(text));

      setMesajVin(getExtractIesiri.mesaj);
      setColor(getExtractIesiri.color);
    }

    setLoading(false);
  }

  function getData() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
  }

  let txt = <>Va rugam asteptati!</>;

  const error = () => {
    Modal.error({
      title: "This is a error message",
      content: (
        <div>
          <p>some messages...some messages...</p>
          <p>some messages...some messages...</p>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <>
      <Spin tip={txt} size="large" spinning={isLoading}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Card
            loading={isLoading}
            actions={[
              items.length === 0 ? (
                <Button
                  icon={<CloudDownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px" }}
                  type="link"
                  key="extrage"
                  onClick={() => extrageDatele()}
                >
                  Extrage datele
                </Button>
              ) : (
                <>
                  <Button
                    icon={<DownloadOutlined style={{ fontSize: "26px" }} />}
                    style={{ fontSize: "16px" }}
                    type="link"
                    key="descarca1"
                    onClick={() => handleSubmit()}
                  >
                    Descarca iesirile
                  </Button>

                  <Button
                    icon={<DownloadOutlined style={{ fontSize: "26px" }} />}
                    style={{ fontSize: "16px" }}
                    type="link"
                    key="descarca2"
                    onClick={() => downloadClientsFile()}
                  >
                    Descarca clientii
                  </Button>
                </>
              ),
            ]}
            style={{
              minWidth: 300,
            }}
          >
            <Card.Meta
              title={"Facturile de iesire"}
              description={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      color: "black",
                      alignItems: "center",
                    }}
                  >
                    {items.length === 0 && <>Extragerea facturilor de iesire</>}
                    <div style={{ color: `${color}`, fontWeight: "bold" }}>
                      {mesajVin}
                    </div>
                    {items.length > 0 && (
                      <div>Numarul de facturi este: {count?.length}</div>
                    )}
                  </div>
                </>
              }
            />
          </Card>
        </div>
      </Spin>
      <Modal
        open={eroareUM.length > 0}
        centered
        footer={
          <Button
            type="primary"
            size="normal"
            onClick={() => navigate(`/firme/${idFirma}`)}
          >
            {`Apasa aici pentru a face modificarile`}
          </Button>
        }
      >
        <>
          <h3 style={{ color: "red" }}>Eroare la exportul datelor!</h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              Pentru a exporta corect datele trebuie sa introduceti urmatoarele
              unitati de masura in setarile initiale la rubrica 'Gestiuni de
              iesire' si sa le setati contul aferent din SAGA
            </div>
            {[...new Set(eroareUM)].map((um, i) => (
              <div style={{ marginLeft: "5px", marginRight: "5px" }} key={i}>
                {um}
              </div>
            ))}
            *respectarea majusculelor este importanta!
          </div>
        </>
      </Modal>
    </>
  );
}

export default Iesiri;
