import axios from "axios";
import { apiUrl } from "../../../app/url";

export const getIntrari = async (params) => {
  try {
    const { data } = await axios.get(`${apiUrl}/intrari/`, {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      params: {
        CIF: params.CIF,
        rangeDate: params.rangeDate,
        contIntrari: params.contIntrari,
        idUser: params.idUser,
      },
    });
    return {
      data: data,
      mesaj: "Extragerea datelor finalizata!",
      color: "green",
    };
  } catch (error) {
    return { data: [], mesaj: error.response.data.message, color: "red" };
  }
};

export const getIncasari = async (params) => {
  try {
    const { data } = await axios.get(`${apiUrl}/incasari/`, {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      params: {
        CIF: params.CIF,
        rangeDate: params.rangeDate,
        contIncasari: params.contIncasari,
        NumeCompanie: params.NumeCompanie,
        idUser: params.idUser,
      },
    });
    return {
      data: data,
      mesaj: "Extragerea datelor finalizata!",
      color: "green",
    };
  } catch (error) {
    return { data: [], mesaj: error.response.data.message, color: "red" };
  }
};

export const getIesiri = async (params) => {
  try {
    const { data } = await axios.get(`${apiUrl}/iesiri/`, {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      params: {
        CIF: params.CIF,
        rangeDate: params.rangeDate,
        idUser: params.idUser,
      },
    });
    return {
      data: data,
      mesaj: "Extragerea datelor finalizata!",
      color: "green",
    };
  } catch (error) {
    return { data: [], mesaj: error.response.data.message, color: "red" };
  }
};

export const getPlati = async (params) => {
  try {
    const { data } = await axios.get(`${apiUrl}/plati/`, {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
      params: {
        CIF: params.CIF,
        rangeDate: params.rangeDate,
        idUser: params.idUser,
      },
    });
    return {
      data: data,
      mesaj: "Extragerea datelor finalizata!",
      color: "green",
    };
  } catch (error) {
    return { data: [], mesaj: error.response.data.message, color: "red" };
  }
};
