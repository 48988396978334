import useAuth from "../../hooks/useAuth";
import FirmaNouForm from "./modal/FirmaNouForm";
import { useGetFirmeQuery } from "./firmeApiSlice";
import { useParams } from "react-router-dom";

function EditFirma() {
  const { id } = useAuth();

  const { idFirma } = useParams();

  const { firme } = useGetFirmeQuery(id, {
    selectFromResult: ({ data }) => ({
      firme: data?.ids.map((id) => {
        return data?.entities[id];
      }),
    }),
  });

  function getFirma(idFirma) {
    let foundFirma = firme.find((e) => e._id === idFirma);
    return foundFirma;
  }

  const content = <FirmaNouForm userId={id} idFirma={getFirma(idFirma)} />;

  return content;
}

export default EditFirma;
