export const apiUrl = "https://apiconta.autoviva.ro";
// export const apiUrl = "http://localhost:3777";

export function getData() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return `${dd}.${mm}.${yyyy}`;
}
