import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css";

import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation } from "./authApiSlice";
import usePersist from "../../hooks/usePersist";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const Login = () => {
  useTitle("Export date");

  const userRef = useRef();
  const errRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();
  const [isSignUp, setIsSignUp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (EMAIL_REGEX.test(email)) {
      try {
        const { accessToken } = await login({ email, password }).unwrap();

        dispatch(setCredentials({ accessToken }));
        setEmail("");
        setPassword("");
        navigate("/");
      } catch (err) {
        if (!err.status) {
          setErrMsg("Serverul nu raspunde");
        } else if (err.status === 400) {
          setErrMsg("Va rugam sa introduceti o parola");
        } else if (err.status === 401) {
          setErrMsg("Acces neautorizat");
        } else {
          setErrMsg(err.data?.message);
        }
        errRef.current.focus();
      }
    } else {
      setErrMsg("Email-ul introdus nu este valid");
    }
  };

  const handleChange = () => {
    console.log("date de inregistare");
  };

  const handleUserInput = (e) => setEmail(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);

  // const errClass = errMsg ? "errmsg" : "offscreen";
  //console.log(errClass); //

  if (isLoading) return <PulseLoader color={"#FFF"} />;

  const content = (
    <div className="Auth">
      {/* left side */}

      <div className="a-left">
        {/* <img src={Logo} alt="" /> */}

        <div className="Webname">
          <h1>Export</h1>
          <h1>Smartbill</h1>
        </div>
      </div>

      {/* right form side */}

      <div className="a-right">
        <form className="infoForm authForm" onSubmit={handleSubmit}>
          <h3>{isSignUp ? "Inregistrare" : "Autentificare"}</h3>

          {isSignUp && <> Inregistrarile sunt suspendate momentan!</>}
          <div>
            <Input
              required
              type="text"
              placeholder="Email-ul dvs."
              name="email"
              ref={userRef}
              value={email}
              onChange={handleUserInput}
              autoComplete="off"
              disabled={isSignUp}
            />
          </div>
          <div>
            <Input.Password
              required
              placeholder="Parola"
              name="password"
              value={password}
              onChange={handlePwdInput}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              disabled={isSignUp}
            />
            {isSignUp && (
              <Input.Password
                required
                name="confirmpass"
                placeholder="Confirmare parola"
                onChange={handleChange}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                disabled={isSignUp}
              />
            )}
          </div>

          {errMsg && (
            <div style={{ color: "red", fontSize: "1rem" }}>{errMsg}</div>
          )}

          {/* <span
            style={{
              color: "red",
              fontSize: "12px",
              alignSelf: "flex-end",
              marginRight: "5px",
              //display: confirmPass ? "none" : "block",
            }}
          >
            *Confirm password is not same
          </span> */}
          {!isSignUp && (
            <label htmlFor="persist" className="form__persist">
              <input
                type="checkbox"
                className="form__checkbox"
                id="persist"
                onChange={handleToggle}
                checked={persist}
              />
              &nbsp;Logare prelungita
            </label>
          )}

          <div>
            <span
              style={{
                fontSize: "12px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                // resetForm();
                setIsSignUp((prev) => !prev);
              }}
            >
              {isSignUp ? "Ai deja un cont" : "Nu ai un cont Înscrie-te"}
            </span>
            <button
              className="button infoButton"
              type="Submit"
              disabled={isSignUp}
            >
              {isSignUp ? "Inregistrare" : "Conectare"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return content;
};
export default Login;
