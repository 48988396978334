import React, { useEffect, useState } from "react";
import { useGetFirmeQuery, useDeleteFirmaMutation } from "./firmeApiSlice";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { message, Card } from "antd";
import { useNavigate } from "react-router-dom";

function FirmeList() {
  const [firmeData, setFirmeData] = useState([]);

  const navigate = useNavigate();

  const { id } = useAuth();

  const {
    data: firme,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetFirmeQuery(id, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const [deleteFirma] = useDeleteFirmaMutation();

  const getFirme = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(firme?.entities).map(
        (key) => firme.entities[key]
      );
      setFirmeData(result);
    }
  };

  useEffect(() => {
    getFirme();
    // eslint-disable-next-line
  }, [firme]);

  const onDeleteFirmaClicked = async (record) => {
    try {
      message.success("Firma a fost sters cu succes");

      await deleteFirma({ id: record }).unwrap();
    } catch (err) {
      message.error("Nu se poate sterge acesta firma!");
    }
  };

  let content;

  if (isLoading) content = <PulseLoader color={"#FFF"} />;

  content = (
    <>
      {isError && <p className="errmsg">{error?.data?.message}</p>}

      {isSuccess && (
        <>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {firmeData.map((item, i) => (
              <Card
                key={i}
                loading={isLoading}
                actions={[
                  <EditOutlined
                    key="edit"
                    onClick={() => navigate(`/firme/${item.id}`)}
                  />,
                  <DeleteOutlined
                    key="setting"
                    onClick={() => onDeleteFirmaClicked(item.id)}
                  />,
                ]}
                style={{
                  minWidth: 300,
                }}
              >
                <Card.Meta
                  title={item.numeFirma}
                  description={
                    <>
                      <p>CIF: {item.cif}</p>
                    </>
                  }
                />
              </Card>
            ))}
          </div>
        </>
      )}
    </>
  );

  return content;
}

export default FirmeList;
