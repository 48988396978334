import React, { useEffect, useState } from "react";
import { getIntrari } from "../source/api";
import { getData } from "../../../app/url";
import { Spin, Card, Button } from "antd";
import { CloudDownloadOutlined, DownloadOutlined } from "@ant-design/icons";

function Intrare({
  CIF,
  NumeCompanie,
  contIntrari,
  fisiere,
  rangeDate,
  token,
  idUser,
}) {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [xmlFile, setXmlFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [mesajVin, setMesajVin] = useState("");
  const [color, setColor] = useState("black");

  // console.log(items)

  function gestiuniXML(gests, nir) {
    const newGests = gests?.filter((obj) => Object.keys(obj).length !== 0);
    let gestiuni = "";
    for (let gesti in newGests) {
      gestiuni += `
      <Linie>
              <LinieNrCrt>${Number(gesti) + 1}</LinieNrCrt>
              <Gestiune>${newGests[gesti].gestiune}</Gestiune>
              <Activitate/>
              <Descriere/>
              <CodArticolFurnizor/>
              <CodArticolClient/>
              <CodBare/>
              <InformatiiSuplimentare>Numar NIR: ${nir}</InformatiiSuplimentare>
              <UM>B</UM>
              <Cantitate>1</Cantitate>
              <Pret>${newGests[gesti].valFaraTVA}</Pret>
              <Valoare>${newGests[gesti].valFaraTVA}</Valoare>
              <ProcTVA>${newGests[gesti].tva}</ProcTVA>
              <TVA>${newGests[gesti].valTVA}</TVA>
              <Cont>${newGests[gesti].cont}</Cont>
              <PretVanzare>${
                newGests[gesti].valoareVanzareCuTVAFinal
              }</PretVanzare>
            </Linie>
      `;
    }

    return gestiuni;
  }

  function factura(obj) {
    let xml = "";
    for (let prop in obj) {
      xml += `
      <Factura>
        <Antet>
          <FurnizorNume/>
          <FurnizorCIF>${obj[prop].cif}</FurnizorCIF>
          <FurnizorNrRegCom/>
          <FurnizorCapital/>
          <FurnizorTara/>
          <FurnizorAdresa/>
          <FurnizorJudet/>
          <FurnizorBanca/>
          <FurnizorIBAN/>
          <FurnizorInformatiiSuplimentare/>
          <ClientNume>${NumeCompanie}</ClientNume>
          <ClientInformatiiSuplimentare/>
          <ClientCIF>${CIF}</ClientCIF>
          <ClientNrRegCom/>
          <ClientAdresa/>
          <ClientJudet/>
          <ClientBanca/>
          <ClientIBAN/>
          <FacturaNumar>${obj[prop].nrFactura}</FacturaNumar>
          <FacturaData>${obj[prop].dataFacturii}</FacturaData>
          <FacturaScadenta/>
          <FacturaTaxareInversa>Nu</FacturaTaxareInversa>
          <FacturaTVAIncasare>Da</FacturaTVAIncasare>
          <FacturaInformatiiSuplimentare/>
          <FacturaMoneda>RON</FacturaMoneda>
          <FacturaCotaTVA>TVA (19)</FacturaCotaTVA>
          <FacturaGreutate>0</FacturaGreutate>
          <FacturaTip/>
        </Antet>
        <Detalii>
          <Continut>
            ${gestiuniXML(obj[prop].gestiuni, obj[prop].nir)}
          </Continut>
          <txtObservatii1/>
        </Detalii>
        <Sumar>
          <TotalValoare/>
          <TotalTVA/>
          <Total/>
        </Sumar>
        <Observatii>
          <txtObservatii/>
          <SoldClient/>
          <ModalitatePlata/>
        </Observatii>
      </Factura>
      `;
    }

    return xml;
  }

  function JSONtoXML(obj) {
    let xml = `
    <Facturi>
      ${factura(obj)}
    </Facturi>
    `;
    return xml;
  }

  useEffect(() => {
    setItems([]);
    setMesajVin("");
  }, [fisiere]);

  useEffect(() => {
    setError(null);

    setXmlFile(JSONtoXML(items));

    // eslint-disable-next-line
  }, [items]);

  function handleSubmit() {
    if (error === "eroare") {
      alert("Acest fisier nu se poate exporta!");
    } else {
      const data_str =
        "data:text/xml;charset=utf-8," + encodeURIComponent(xmlFile);
      let anchor = document.createElement("a");
      anchor.setAttribute("href", data_str);
      anchor.setAttribute(
        "download",
        `F_${CIF}_INTRARI_${
          Math.floor(Math.random() * 10001) + 9999
        }_${getData()}.xml`
      );
      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();
    }
  }

  async function addIntrari() {
    setLoading(true);

    const getExtractIntrari = await getIntrari({
      CIF,
      rangeDate,
      contIntrari,
      token,
      idUser,
    });

    if (getExtractIntrari.data === "eroare") {
      setItems([]);
      setMesajVin("A aparut o eroare! Incercati din nou!");
      setColor("red");
    } else {
      setItems(getExtractIntrari.data);
      setMesajVin(getExtractIntrari.mesaj);
      setColor(getExtractIntrari.color);
    }

    setLoading(false);
  }

  let txt = <>Va rugam asteptati!</>;

  return (
    <>
      <Spin tip={txt} size="large" spinning={isLoading}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Card
            loading={isLoading}
            actions={[
              items.length === 0 ? (
                <Button
                  icon={<CloudDownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px" }}
                  type="link"
                  key="extrage"
                  onClick={() => addIntrari()}
                >
                  Extrage datele
                </Button>
              ) : (
                <Button
                  icon={<DownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px" }}
                  type="link"
                  key="descarca"
                  onClick={() => handleSubmit()}
                >
                  Descarca intrarile
                </Button>
              ),
            ]}
            style={{
              minWidth: 300,
            }}
          >
            <Card.Meta
              title={"Facturile de intrare"}
              description={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      color: "black",
                      alignItems: "center",
                    }}
                  >
                    {items.length === 0 && (
                      <>Extragerea facturilor de intrare</>
                    )}
                    <div style={{ color: `${color}`, fontWeight: "bold" }}>
                      {mesajVin}
                    </div>
                    {items.length > 0 && (
                      <div>Numarul de facturi este: {items.length}</div>
                    )}
                  </div>
                </>
              }
            />
          </Card>
        </div>
      </Spin>
    </>
  );
}

export default Intrare;
