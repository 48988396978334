import React, { useEffect, useState } from "react";
import { store } from "../../app/store";
import { useUpdateUserMutation, usersApiSlice } from "../user/usersApiSlice";
import { Button, Modal, Form, Input } from "antd";

function FirstLogin({ email, id, active }) {
  const [editModalVisibilty, setEditModalVisibilty] = useState(true);

  const [updateUser] = useUpdateUserMutation();

  const [companyName, setCompanyName] = useState();
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [address, setAddress] = useState();
  const [validAddress, setValidAddress] = useState(false);
  const [cif, setCif] = useState();
  const [validCif, setValidCif] = useState(false);
  const [regCom, setRegCom] = useState();
  const [validRegCom, setValidRegCom] = useState(false);

  const [userSmartBill, setUserSmartBill] = useState();
  const [validUserSmartBill, setValidUserSmartBill] = useState(false);

  const [passSmartBill, setPassSmartBill] = useState();
  const [validPassSmartBill, setValidPassSmartBill] = useState(false);

  const [nextStep, setNextStep] = useState(false);

  useEffect(() => {
    setValidCompanyName(companyName);
  }, [companyName]);

  useEffect(() => {
    setValidAddress(address);
  }, [address]);

  useEffect(() => {
    setValidCif(cif);
  }, [cif]);

  useEffect(() => {
    setValidRegCom(regCom);
  }, [regCom]);

  useEffect(() => {
    setValidUserSmartBill(userSmartBill);
  }, [userSmartBill]);

  useEffect(() => {
    setValidPassSmartBill(passSmartBill);
  }, [passSmartBill]);

  const onCompanyNameChanged = (e) => setCompanyName(e.target.value);
  const onAddressChanged = (e) => setAddress(e.target.value);
  const onCifChanged = (e) => setCif(e.target.value.toUpperCase());
  const onRegComChanged = (e) => setRegCom(e.target.value);
  const onUserSmartBillChanged = (e) => setUserSmartBill(e.target.value);
  const onPassSmartBillChanged = (e) => setPassSmartBill(e.target.value);

  const onSaveUserClicked = async (e) => {
    await updateUser({
      id: id,
      email,
      companyName,
      address,
      cif,
      regCom,
      userSmartBill,
      passSmartBill,
      active,
    });

    store.dispatch(
      usersApiSlice.util.prefetch("getUser", id, {
        force: true,
      })
    );

    // inchidem fereastra
    setEditModalVisibilty(false);
  };

  let canSave = [validCompanyName, validAddress, validCif, validRegCom].every(
    Boolean
  );

  let canSave2 = [validUserSmartBill, validPassSmartBill].every(Boolean);

  const validCompanyNameClass = !validCompanyName
    ? "form__input--incomplete"
    : "";
  const validAddressClass = !validAddress ? "form__input--incomplete" : "";
  const validCifClass = !validCif ? "form__input--incomplete" : "";
  const validRegComClass = !validRegCom ? "form__input--incomplete" : "";

  const validUserSmartBillClass = !validUserSmartBill
    ? "form__input--incomplete"
    : "";
  const validPassSmartBillClass = !validPassSmartBill
    ? "form__input--incomplete"
    : "";

  return (
    <>
      {editModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditModalVisibilty(false);
          }}
          open={editModalVisibilty}
          centered={true}
          closable={false}
          title={`Bine ai venit ${email}`}
          footer={false}
          maskClosable={false}
          keyboard={false}
        >
          {!nextStep && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginBottom: "10px" }}>
                Primul pas este sa completezi datele firmei dvs.
              </div>
              <Form>
                <div>CIF:</div>
                <Input
                  style={{ marginBottom: "10px" }}
                  className={` ${validCifClass}`}
                  id="cif"
                  name="cif"
                  type="text"
                  autoComplete="off"
                  value={cif}
                  onChange={onCifChanged}
                />

                <div>Numele companiei:</div>
                <Input
                  style={{ marginBottom: "10px" }}
                  className={`${validCompanyNameClass}`}
                  id="companyName"
                  name="companyName"
                  type="text"
                  autoComplete="off"
                  value={companyName}
                  onChange={onCompanyNameChanged}
                />

                <div>Adresa:</div>
                <Input
                  style={{ marginBottom: "10px" }}
                  className={` ${validAddressClass}`}
                  id="address"
                  name="address"
                  type="text"
                  autoComplete="off"
                  value={address}
                  onChange={onAddressChanged}
                />

                <div>Reg. Com.:</div>
                <Input
                  style={{ marginBottom: "10px" }}
                  className={`${validRegComClass}`}
                  id="regCom"
                  name="regCom"
                  type="text"
                  autoComplete="off"
                  value={regCom}
                  onChange={onRegComChanged}
                />

                <div className="d-flex justify-content-end">
                  <Button
                    type="primary"
                    onClick={() => {
                      setNextStep(true);
                    }}
                    disabled={!canSave}
                  >
                    {`Pasul urmator`}
                  </Button>
                </div>
              </Form>
            </div>
          )}

          {nextStep && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginBottom: "10px" }}>
                Al doilea pas este sa completati datele de autentificare in
                Smartbill
              </div>
              <Form>
                <div>Nume utilizator Smartbill:</div>
                <Input
                  style={{ marginBottom: "10px" }}
                  className={` ${validUserSmartBillClass}`}
                  id="userSmartBill"
                  name="userSmartBill"
                  type="text"
                  autoComplete="off"
                  value={userSmartBill}
                  onChange={onUserSmartBillChanged}
                />

                <div>Parola Smartbill:</div>
                <Input.Password
                  style={{ marginBottom: "10px" }}
                  className={`${validPassSmartBillClass}`}
                  id="passSmartBill"
                  name="passSmartBill"
                  type="text"
                  autoComplete="off"
                  value={passSmartBill}
                  onChange={onPassSmartBillChanged}
                />

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      setNextStep(false);
                    }}
                  >
                    {`Inapoi`}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      onSaveUserClicked();
                    }}
                    disabled={!canSave2}
                  >
                    {`Salveaza datele`}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

export default FirstLogin;
