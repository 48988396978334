import React, { useEffect, useState } from "react";
import { useGetUserQuery, useUpdateUserMutation } from "./usersApiSlice";
import PulseLoader from "react-spinners/PulseLoader";
import useAuth from "../../hooks/useAuth";
import { Button, Modal, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";

const USER_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,20}$/;

const UsersList = () => {
  const { id } = useAuth();

  const navigate = useNavigate();

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUserQuery(id, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [editModalVisibilty, setEditModalVisibilty] = useState(false);

  const [email, setUsername] = useState(user ? user.user.email : "");
  const [validUsername, setValidUsername] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [companyName, setCompanyName] = useState(
    user ? user.user.companyName : ""
  );
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [address, setAddress] = useState(user ? user.user.address : "");
  const [validAddress, setValidAddress] = useState(false);
  const [cif, setCif] = useState(user ? user.user.cif : "");
  const [validCif, setValidCif] = useState(false);
  const [regCom, setRegCom] = useState(user ? user.user.regCom : "");
  const [validRegCom, setValidRegCom] = useState(false);

  const [userSmartBill, setUserSmartBill] = useState(
    user ? user.user.userSmartBill : ""
  );
  const [validUserSmartBill, setValidUserSmartBill] = useState(false);

  const [passSmartBill, setPassSmartBill] = useState(
    user ? user.user.passSmartBill : ""
  );
  const [validPassSmartBill, setValidPassSmartBill] = useState(false);

  // const [active] = useState(user?.user.active);

  useEffect(() => {
    setValidUsername(USER_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setValidCompanyName(companyName);
  }, [companyName]);

  useEffect(() => {
    setValidAddress(address);
  }, [address]);

  useEffect(() => {
    setValidCif(cif);
  }, [cif]);

  useEffect(() => {
    setValidRegCom(regCom);
  }, [regCom]);

  useEffect(() => {
    setValidUserSmartBill(userSmartBill);
  }, [userSmartBill]);

  useEffect(() => {
    setValidPassSmartBill(passSmartBill);
  }, [passSmartBill]);

  const onUsernameChanged = (e) => setUsername(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);
  const onCompanyNameChanged = (e) => setCompanyName(e.target.value);
  const onAddressChanged = (e) => setAddress(e.target.value);
  const onCifChanged = (e) => setCif(e.target.value);
  const onRegComChanged = (e) => setRegCom(e.target.value);
  const onUserSmartBillChanged = (e) => setUserSmartBill(e.target.value);
  const onPassSmartBillChanged = (e) => setPassSmartBill(e.target.value);

  const [updateUser] = useUpdateUserMutation();

  let content;

  const getUser = () => {
    if (isLoading) content = <PulseLoader color={"#FFF"} />;

    if (isError) {
      content = <p className="errmsg">{error?.data?.message}</p>;
    }

    if (isSuccess) {
      setUsername(user.user.email);
      setCompanyName(user.user.companyName);
      setAddress(user.user.address);
      setCif(user.user.cif);
      setRegCom(user.user.regCom);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [user, isSuccess]);

  const onSaveUserClicked = async (e) => {
    if (password) {
      await updateUser({
        id: user.user._id,
        email,
        password,
        companyName,
        address,
        cif,
        regCom,
        userSmartBill,
        passSmartBill,
        active: user?.user.active,
      });
    } else {
      await updateUser({
        id: user.user._id,
        email,
        companyName,
        address,
        cif,
        regCom,
        userSmartBill,
        passSmartBill,
        active: user?.user.active,
      });
    }
  };

  let canSave;
  if (password) {
    canSave =
      [
        // roles.length,
        validUsername,
        validPassword,
        validCompanyName,
        validAddress,
        validCif,
        validRegCom,
        validUserSmartBill,
        validPassSmartBill,
      ].every(Boolean) && !isLoading;
  } else {
    canSave =
      [
        // roles.length,
        validUsername,
        validCompanyName,
        validAddress,
        validCif,
        validRegCom,
        validUserSmartBill,
        validPassSmartBill,
      ].every(Boolean) && !isLoading;
  }

  const validUserClass = !validUsername ? "form__input--incomplete" : "";
  const validPwdClass =
    password && !validPassword ? "form__input--incomplete" : "";

  const validCompanyNameClass = !validCompanyName
    ? "form__input--incomplete"
    : "";
  const validAddressClass = !validAddress ? "form__input--incomplete" : "";
  const validCifClass = !validCif ? "form__input--incomplete" : "";
  const validRegComClass = !validRegCom ? "form__input--incomplete" : "";

  const validUserSmartBillClass = !validUserSmartBill
    ? "form__input--incomplete"
    : "";
  const validPassSmartBillClass = !validPassSmartBill
    ? "form__input--incomplete"
    : "";

  if (isSuccess) {
    content = (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div>
            <h3>{companyName}</h3>
            <h5>{cif}</h5>
            <h5>{regCom}</h5>
            <h5>{address}</h5>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              style={{ width: 170, marginBottom: "20px" }}
              type="primary"
              onClick={() => setEditModalVisibilty(true)}
            >
              Modifica datele contului
            </Button>

            <Button
              style={{ width: 170 }}
              type="primary"
              onClick={() => {
                navigate("/firme/noua");
              }}
            >
              Adauga o firma noua
            </Button>
          </div>
        </div>

        {editModalVisibilty && (
          <Modal
            onCancel={() => {
              setEditModalVisibilty(false);
            }}
            open={editModalVisibilty}
            centered
            title={`Modifica datele companiei`}
            footer={false}
          >
            <Form>
              <div>Email:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={`${validUserClass}`}
                id="email"
                name="email"
                type="text"
                autoComplete="off"
                value={email}
                onChange={onUsernameChanged}
              />

              <div>
                Parola: ( parola ramane aceeasi daca nu completati nimic )
              </div>
              <Input.Password
                style={{ marginBottom: "10px" }}
                className={` ${validPwdClass}`}
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={onPasswordChanged}
              />

              <div>Numele companiei:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={` ${validCompanyNameClass}`}
                id="companyName"
                name="companyName"
                type="text"
                autoComplete="off"
                value={companyName}
                onChange={onCompanyNameChanged}
              />

              <div>Adresa:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={` ${validAddressClass}`}
                id="address"
                name="address"
                type="text"
                autoComplete="off"
                value={address}
                onChange={onAddressChanged}
              />

              <div>CIF:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={`${validCifClass}`}
                id="cif"
                name="cif"
                type="text"
                autoComplete="off"
                value={cif}
                onChange={onCifChanged}
              />

              <div>Reg. Com.:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={` ${validRegComClass}`}
                id="regCom"
                name="regCom"
                type="text"
                autoComplete="off"
                value={regCom}
                onChange={onRegComChanged}
              />

              <div>Nume utilizator Smartbill:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={` ${validUserSmartBillClass}`}
                id="userSmartBill"
                name="userSmartBill"
                type="text"
                autoComplete="off"
                value={userSmartBill}
                onChange={onUserSmartBillChanged}
              />

              <div>Parola Smartbill:</div>
              <Input.Password
                style={{ marginBottom: "10px" }}
                className={`${validPassSmartBillClass}`}
                id="passSmartBill"
                name="passSmartBill"
                type="text"
                autoComplete="off"
                value={passSmartBill}
                onChange={onPassSmartBillChanged}
              />

              <div className="d-flex justify-content-end">
                <Button
                  type="primary"
                  onClick={() => {
                    onSaveUserClicked();
                    setEditModalVisibilty(false);
                  }}
                  disabled={!canSave}
                >
                  {`Salveaza modificarile`}
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </>
    );
  }

  return content;
};
export default UsersList;
