import Intrare from "./componente/Intrare";
import Iesiri from "./componente/Iesiri";
import Incasari from "./componente/Incasari";
import Plati from "./componente/Plati";
import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import PulseLoader from "react-spinners/PulseLoader";
import { useGetFirmeQuery } from "../firme/firmeApiSlice";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectCurrentToken } from "../auth/authSlice";

const { RangePicker } = DatePicker;

function dataFormat(data) {
  return `${String(data.$D).padStart(2, "0")}/${String(data.$M + 1).padStart(
    2,
    "0"
  )}/${data.$y}`;
}

const date = new Date();
const previousMonth = new Date(date.getTime());
previousMonth.setDate(0);

const mm = String(previousMonth.getMonth() + 1).padStart(2, "0"); //January is 0!
const yyyy = previousMonth.getFullYear();
const endDateM = new Date(yyyy, previousMonth.getMonth() + 1, 0);
const dd = String(endDateM.getDate()).padStart(2, "0");

const startDate = `01/${mm}/${yyyy}`;
const endDate = `${dd}/${mm}/${yyyy}`;

function ExtractiiPage() {
  const [date, setDate] = useState();
  const [rangeDate, setRangeDate] = useState();
  const [firma, setFirma] = useState("");

  const { idUser, idFirma } = useParams();

  const token = useSelector(selectCurrentToken);

  const {
    data: firme,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetFirmeQuery(idUser);

  const getFirme = () => {
    // transformarea intrarilor in obiect pentru citit lista
    if (isSuccess) {
      var result = Object.keys(firme?.entities).map(
        (key) => firme.entities[key]
      );

      var result2 = result.filter((obj) => {
        return obj.id === idFirma;
      });

      setFirma(result2[0]);
    }
  };

  useEffect(() => {
    getFirme();
    // eslint-disable-next-line
  }, [firme, idFirma]);

  useEffect(() => {
    date && setRangeDate(dataFormat(date[0]) + " - " + dataFormat(date[1]));
    !date && setRangeDate(startDate + " - " + endDate);
  }, [date]);

  const dateFormat = "DD/MM/YYYY";

  return (
    <>
      {isLoading ? (
        <PulseLoader color={"#FFF"} />
      ) : (
        <div>
          {firma ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <h3>{firma.numeFirma}</h3>
                  <RangePicker
                    format={dateFormat}
                    defaultValue={[
                      dayjs(startDate, dateFormat),
                      dayjs(endDate, dateFormat),
                    ]}
                    onChange={(date) => setDate(date)}
                  />
                </div>
              </div>

              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {firma.conta.intrari && (
                  <Intrare
                    CIF={firma.cif}
                    NumeCompanie={firma.numeFirma}
                    contIntrari={firma.contIntrari}
                    fisiere={[]}
                    rangeDate={rangeDate}
                    token={token}
                    idUser={idUser}
                  />
                )}

                {firma.conta.iesiri && (
                  <Iesiri
                    CIF={firma.cif}
                    conturiIesiri={firma.contIesiri}
                    fisiere={[]}
                    rangeDate={rangeDate}
                    token={token}
                    idUser={idUser}
                  />
                )}
                {firma.conta.incasari && (
                  <Incasari
                    CIF={firma.cif}
                    contIncasari={firma.contIncasari}
                    NumeCompanie={firma.numeFirma}
                    fisiere={[]}
                    rangeDate={rangeDate}
                    token={token}
                    idUser={idUser}
                  />
                )}
                {firma.conta.plati && (
                  <Plati
                    CIF={firma.cif}
                    NumeCompanie={firma.numeFirma}
                    fisiere={[]}
                    rangeDate={rangeDate}
                    token={token}
                    idUser={idUser}
                  />
                )}
              </div>
            </>
          ) : (
            <h2
              style={{
                marginTop: "100px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Nicio firma nu este selectata!
            </h2>
          )}
        </div>
      )}
    </>
  );
}

export default ExtractiiPage;
