import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const firmeAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = firmeAdapter.getInitialState();

export const firmeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFirme: builder.query({
      query: (id) => ({
        url: `/firme/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedFirme = responseData.map((firma) => {
          firma.id = firma._id;
          return firma;
        });
        return firmeAdapter.setAll(initialState, loadedFirme);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Firma", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Firma", id })),
          ];
        } else return [{ type: "Firma", id: "LIST" }];
      },
    }),
    addNewFirma: builder.mutation({
      query: (initialFirma) => ({
        url: "/firme",
        method: "POST",
        body: {
          ...initialFirma,
        },
      }),
      invalidatesTags: [{ type: "Firma", id: "LIST" }],
    }),
    updateFirma: builder.mutation({
      query: (initialFirma) => ({
        url: "/firme",
        method: "PATCH",
        body: {
          ...initialFirma,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Firma", id: arg.id }],
    }),
    deleteFirma: builder.mutation({
      query: ({ id }) => ({
        url: `/firme`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Firma", id: arg.id }],
    }),
  }),
});

export const {
  useGetFirmeQuery,
  useAddNewFirmaMutation,
  useUpdateFirmaMutation,
  useDeleteFirmaMutation,
} = firmeApiSlice;

// returns the query result object
export const selectFirmeResult = firmeApiSlice.endpoints.getFirme.select();

// creates memoized selector
const selectFirmeData = createSelector(
  selectFirmeResult,
  (firmeResult) => firmeResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllFirme,
  selectById: selectFirmaById,
  selectIds: selectFirmaIds,
  // Pass in a selector that returns the firme slice of state
} = firmeAdapter.getSelectors(
  (state) => selectFirmeData(state) ?? initialState
);
