import React, { useEffect, useState } from "react";
import { Spin, Card, Button } from "antd";
import { getIncasari } from "../source/api";
import { getData } from "../../../app/url";
import { CloudDownloadOutlined, DownloadOutlined } from "@ant-design/icons";

function Incasari({
  CIF,
  contIncasari,
  NumeCompanie,
  fisiere,
  rangeDate,
  token,
  idUser,
}) {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [xmlFile, setXmlFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [mesajVin, setMesajVin] = useState("");
  const [color, setColor] = useState("black");

  let txt = <>Va rugam asteptati!</>;

  useEffect(() => {
    setItems([]);
    setMesajVin("");
  }, [fisiere]);

  useEffect(() => {
    setError(null);

    setXmlFile(JSONtoXML(items));

    // eslint-disable-next-line
  }, [items]);

  function JSONtoXML(obj) {
    let xml = `
    <Incasari>
      ${factura(obj)}
    </Incasari>
    `;
    return xml;
  }

  function factura(obj) {
    let xml = "";
    for (let prop in obj) {
      xml += `
      <Linie>
        <Data>${obj[prop].data}</Data>
        <Numar>${obj[prop].numarBon}</Numar>
        <Suma>${obj[prop].suma}</Suma>
        <Cont>${obj[prop].cont}</Cont>
        <ContClient> </ContClient>
        <Explicatie>${obj[prop].explicatie}</Explicatie>
        <FacturaID> </FacturaID>
        <FacturaNumar>${obj[prop].numarFactura.toUpperCase()}</FacturaNumar>
        <CodFiscal/>
        <Moneda/>
      </Linie>
      `;
    }

    return xml;
  }

  async function addIncasari() {
    setLoading(true);

    const getExtractIncasari = await getIncasari({
      CIF,
      rangeDate,
      token,
      contIncasari,
      NumeCompanie,
      idUser,
    });

    if (getExtractIncasari.data === "eroare") {
      setItems([]);
      setMesajVin("A aparut o eroare! Incercati din nou!");
      setColor("red");
    } else {
      setItems(getExtractIncasari.data);
      setMesajVin(getExtractIncasari.mesaj);
      setColor(getExtractIncasari.color);
    }

    setLoading(false);
  }

  function handleSubmit() {
    if (error === "eroare") {
      alert("Acest fisier nu se poate exporta!");
    } else {
      const data_str =
        "data:text/xml;charset=utf-8," + encodeURIComponent(xmlFile);
      let anchor = document.createElement("a");
      anchor.setAttribute("href", data_str);
      anchor.setAttribute("download", `I_${getData()}_${CIF}.xml`);
      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();
    }
  }

  return (
    <>
      <Spin tip={txt} size="large" spinning={isLoading}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Card
            loading={isLoading}
            actions={[
              items.length === 0 ? (
                <Button
                  icon={<CloudDownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px" }}
                  type="link"
                  key="extrage"
                  onClick={() => addIncasari()}
                >
                  Extrage datele
                </Button>
              ) : (
                <Button
                  icon={<DownloadOutlined style={{ fontSize: "26px" }} />}
                  style={{ fontSize: "16px" }}
                  type="link"
                  key="descarca"
                  onClick={() => handleSubmit()}
                >
                  Descarca incasarile
                </Button>
              ),
            ]}
            style={{
              minWidth: 300,
            }}
          >
            <Card.Meta
              title={"Incasarile"}
              description={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      color: "black",
                      alignItems: "center",
                    }}
                  >
                    {items.length === 0 && <>Extragerea incasarilor</>}
                    <div style={{ color: `${color}`, fontWeight: "bold" }}>
                      {mesajVin}
                    </div>
                    {items.length > 0 && (
                      <div>Numarul de incasari este: {items.length}</div>
                    )}
                  </div>
                </>
              }
            />
          </Card>
        </div>
      </Spin>
    </>
  );
}

export default Incasari;
