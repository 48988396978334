import useAuth from "../../hooks/useAuth";
import FirmaNouForm from "./modal/FirmaNouForm";

function FirmaNou() {
  const { id } = useAuth();

  const content = <FirmaNouForm userId={id} idFirma={null} />;

  return content;
}

export default FirmaNou;
